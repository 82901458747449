.apps-sites-select-box {
  margin-bottom: 30px;

  .apps-search-input {
    width: 100%;
    height: 40px;

    & > div {
      border-radius: 8px;
    }

    & input {
      padding: 8px 14px 8px 0;
    }

  }

  .app-select-wrap {
    width: 100%;
    height: 402px;
    border: 1px solid #E6E9ED;
    border-radius: 8px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 16px;
    min-height: 16px;
    outline: 0;
    z-index: 3;

    .app-select-wrap-loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 40px;
      overflow: hidden;
      padding-left: 15px;
      background-color: transparent;
      z-index: 3;

      p {
        position: absolute;
        top: 50%;
        left: 44%;
        font-size: 20px;
      }

      .app-select-body-left-item {
        display: flex;
        width: 47%;
        height: 36px;
        padding: 10px 0;
        border-bottom: 1px solid #E6E9ED;
      }
    }

    .app-select-header {
      box-sizing: border-box;
      height: 52px;
      background-color: #F4F6FA;
      display: flex;
      align-items: center;
      padding: 16px;

      .app-select-header-icons-delete {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.2;
        cursor: pointer;

        &:hover {
          opacity: 0.4;
        }
      }

      & > div {
        & > div {
          font-family: Source Sans Pro, serif ;
          font-size: 12px;
          font-weight: 600;
          line-height: 15.08px;
          text-align: right;
          color: #292F41
        }

        & > div:focus {
          background-color: transparent !important;
        }
      }
    }

    .app-select-body {
      display: flex;
      flex-direction: row;

      .app-select-body-left {
        width: 50%;

        height: 348px;
        padding: 0 10px 0 16px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 3px;

        }

        &::-webkit-scrollbar-track {
          //background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #D9D9D9;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #D9D9D9;
        }

        .app-select-body-left-item {
          display: flex;
          width: 100%;
          height: 36px;
          padding: 10px 0;
          border-bottom: 1px solid #E6E9ED;


          & .app-select-body-left-item-select-icons {
            display: none;
            margin-left: 12px;
            margin-top: -2px;
            width: 47px;

            .app-select-body-left-item-select-icons-check,
            .app-select-body-left-item-select-icons-block {
              text-align: center;
              width: 21px;
              height: 21px;
              float: left;
              background-color: #E6E9ED;
              border-radius: 4px;
              cursor: pointer;
            }

            .app-select-body-left-item-select-icons-block {
              padding-top: 1px;
              margin-left: 3px;
              float: right;
            }
          }

          &:hover {
            & .app-select-body-left-item-select-icons {
              display: block;
            }
          }

          & svg {
            width: 14px;
            height: 14px;
            margin-top: 1px;
          }

          .app-select-body-left-item-label,
          .app-select-body-left-item-text {
            font-family: Source Sans Pro, serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 16.34px;
            text-align: left;
            color: #292F41;
            cursor: default;
          }

          .app-select-body-left-item-label {
            border-right: 1px solid #F4F6FA;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 180px;

          }

          .app-select-body-left-item-text {
            width: 13%;
            margin-left: 8px;
          }
        }
      }

      .app-select-body-right {
        width: 50%;
        height: 348px;
        border-left: 1px solid #E6E9ED;
        margin-left: 2px;


        & .app-select-body-right-header {
          width: 100%;
          height: 31px;
          background-color: rgb(230 233 237 / 30%);
          padding: 10px 16px;

          & > p {
            font-family: Source Sans Pro, serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 16.34px;
            text-align: left;
          }
        }

        & .app-select-body-right-body {
          padding: 0 16px;
          overflow-y: auto;
          height: 317px;
          margin-right: 2px;

          &::-webkit-scrollbar {
            width: 3px;

          }

          &::-webkit-scrollbar-track {
            //background: #f1f1f1;
          }

          &::-webkit-scrollbar-thumb {
            background: #D9D9D9;
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #D9D9D9;
          }

          .app-select-body-right-item {
            display: flex;
            width: 100%;
            height: 36px;
            padding: 10px 0;
            border-bottom: 1px solid #E6E9ED;
            justify-content: space-between;

            & .app-select-body-right-item-select-icons {
              margin-left: 18px;
              width: 43px;

              .app-select-body-right-item-select-icons-delete {
                padding-top: 1px;
                margin-left: 3px;
                text-align: center;
                width: 21px;
                height: 21px;
                float: left;
                border-radius: 4px;
                cursor: pointer;
                float: right;

                & svg {
                  width: 18px;
                  height: 18px;
                  margin-top: -1px;
                  color: #8F929B;
                }
              }
            }

            & svg {
              width: 14px;
              height: 14px;
              margin-top: 0.5px;
            }

            .app-select-body-left-item-label,
            .app-select-body-left-item-text {
              font-family: Source Sans Pro, serif;
              font-size: 13px;
              font-weight: 500;
              line-height: 16.34px;
              text-align: left;
              color: #292F41;
              cursor: default;
            }

            .app-select-body-left-item-label {
              border-right: 1px solid #F4F6FA;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 180px;

            }

            .app-select-body-left-item-text {
              width: 14%;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .chips-view-box {
    display: flex;
    height: 40px;
    padding-top: 8px;

    .chips-view-box-label {
      height: 30px;
      font-size: 13px;
      font-weight: 400;
      padding: 8px 4px;
    }

    .chip-container {
      display: flex;
      overflow: hidden;

      .chip, .view-more {
        height: 32px;
        background-color: #F4F6FA;
        border-radius: 50px;
        margin-left: 8px;
        min-width: 142px;
        max-width: 142px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 18px;
        font-size: 14px;
        padding: 7px 17px;
        text-align: center;

        &.moreChips {
          min-width: 162px;
          max-width: 162px;
        }
      }

      .view-more {
        min-width: 79px;
        max-width: 79px;
        cursor: pointer;
      }
    }
  }
}

.data-tooltip {
  width: 414px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  margin: -10px;
  border: 1px solid #E3E4E5;
  border-radius: 12px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

  .tooltip-header {
    display: flex;
    height: 55px;
    justify-content: space-between;
    padding: 24px 16px 10px 24px;

    .tooltip-header-title {
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #292F41;
      }
    }
  }
  .tooltip-body {
    padding: 0 16px 8px 24px;
    border-bottom: 1px solid #E3E4E5;

    .tooltip-row {
      display: flex;
      flex-direction: row;
      line-height: 16px;
      height: 20px;

      .row-key{
        white-space: nowrap;
        color: #7B7D84;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        margin-right: 4px;
      }
      .row-value{
        font-size: 13px;
        color: #292F41;
        white-space: nowrap;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .tooltip-footer {
    padding: 12px 24px 16px 24px;
    border-bottom: 1px solid #E3E4E5;

  }
  .tooltip-footer-creatives {
    height: fit-content;
    padding: 12px 24px 18px 24px;
  }

  .tooltip-footer,
  .tooltip-footer-creatives {
    display: flex;

    .tooltip-footer-item {
      display: flex;
      flex-direction: column;
      width: 30%;
      align-items: flex-start;

      .row-key{
        white-space: nowrap;
        color: #7B7D84;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        margin-right: 4px;
      }
      .row-value{
        font-size: 13px;
        color: #292F41;
        font-weight: 400;
        line-height: 16px;
        text-align: start;
      }
    }
  }
}
