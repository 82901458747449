.admin-campaign {
  background-color: #ffffff;
  padding: 0.375rem 1rem;
  // min-height: 75px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  align-items: center;

  &::after {
    bottom: 0;
    position: absolute;
    content: "";
    height: 1px;
    background-color: #dfe7f0;
    display: block;
    border-bottom: 1px solid #dfe7f0;
    width: calc(100% - 2rem);
  }
  &.search {
    &::after {
      content: "";
      width: calc(100% - 2rem - 48px);
      left: 64px;
    }
  }
  // flex-direction: column;
  .admin-campaign-image {
    position: relative;
    width: 48px;
    height: 48px;
    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      border: 4px solid #dfe7f0;
      object-fit: scale-down;
    }
    margin-right: 0.75rem;

    .campaign-list-item-status {
      position: absolute;
      top: 31px;
      left: 34px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
  .admin-campaign-content {
    flex: 1;
    .campaign-list-item-status {
      top: 13px;
      position: relative;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    display: flex;
    // justify-content: space-between;
    // align-items: center;
  }
  .admin-campaign-icon {
    margin-left: 2rem;
    // flex: 1;
    .int-icon {
      float: right;
      fill: var(--primary_color);
    }
  }
  .admin-campaign-name {
    font-weight: 600;
    font-size: 1rem;
    color: #292f41;
    letter-spacing: 0;
    text-align: left;
    line-height: 1.5rem;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
  }
  .admin-campaign-date-and-space {
    font-size: 0.75rem;
    // margin-left: 1rem;
    color: rgba(41, 47, 65, 0.5);
    letter-spacing: 0.8px;
    text-align: left;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.4375rem;
    display: flex;
    justify-content: space-between;
  }
}
