.replicate-campaign-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //backgroundColor: 'background.paper',
  border-radius: 24px !important;
  box-shadow: 0 12px 8px -10px rgba(0, 0, 0, 0.12);
  min-width: 680px;
  min-height: 365px;

  .replicate-campaign-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 8px 24px 24px;
    position: relative;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .icon-btn {
      padding: 0 !important;
    }
  }

  .replicate-campaign-body {
    padding: 24px;
    background-color: rgb(244, 246, 250);
    max-width: 680px;

    .description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
      color: #7B7D84;
    }

    .admin-user-search-input-wrap {
      div {
        border-radius: 8px;
      }
      .close {
        top: 12px;
        right: 12px;
      }
      input {
        padding: 12px 24px 12px 12px;
      }
      fieldset {
        border-radius: 12px;
      }
    }

    .admin-user-search-container {
      width: 632px !important;

      .admin-search-list {
        li {
          min-width: 100% !important;
        }
      }
    }
  }

  .replicate-campaign-footer {
    background-color: rgb(244, 246, 250);
    padding: 56px 24px 32px 24px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;

    .button {
      float: right;
      width: 140px;
      height: 32px;
      border-radius: 20px;
    }
  }

}