@keyframes topProgress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes topProgressInvisible {
  0% {
    width: 20%;
  }

  99% {
    width: 100%;
  }

  100% {
    display: none;
  }
}

.top-progress-bar {
  width: 0;
  height: 2px;
  position: fixed;
  left: 0;
  margin: 0;
  padding: 0;
  top: 0;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  z-index: 9999;
  animation-name: topProgress;
  animation-duration: 1000ms;
  animation-timing-function: ease;
}

.top-progress-bar-start {
  width: 100%;
}

.top-progress-bar-invisible {
  animation-name: topProgressInvisible;
  animation-duration: 500ms;
  animation-timing-function: ease;
}


